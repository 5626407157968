<template>
	<div
		class="mt-30 text-left flex-row"
	>
		<CartelSetting
			:user="user"

			class="flex-side mr-30"

			@click="setMenu"
		></CartelSetting>

		<div class="flex-1 bg-white pa-20 radius-20">

			<div class="under-line-dick pb-10 flex-row">
				<h3 class="flex-1">{{  item_menu.title }}</h3>
			</div>

			<mafia0741
				:user="user"
			></mafia0741>
		</div>
	</div>
</template>

<script>
	import CartelSetting from "@/view/Cartel/CartelSetting";
	import Mafia0741 from "@/view/Cartel/mafia074-1";
	export default {
		name: 'mafia074'
		,
		components: {Mafia0741, CartelSetting},
		props: ['user']
		, data: function(){
			return {
				program: {
					name: '카르텔 설정'
					, title: '카르텔 설정'
					, type: 'cartel_sub'
					, not_footer: true
					, not_header: true
					, from: 'mafia049'
					, bg_contents: 'bg-gray01'
				}
				, item_cartel: {}
				, is_trans: false
				, is_confirm: false
				, item_permission: {}
				, item_menu: {

				}
			}
		}
		, computed: {

			is_mine: function(){
				let t = false

				console.log(this.item_cartel.id, this.user.id)
				if(this.item_cartel.user) {
					if (this.item_cartel.user.indexOf(this.user.id) > -1) {
						t = true
					}
				}

				return t
			}
			, is_active: function(){
				let t = '비공개'

				if(this.item_cartel.is_active){
					t = '공개'
				}

				return t
			}
			, is_boss: function(){
				let t = false

				if(this.item_permission.cartl_member_grade_code == 'CA00400001'){
					t = true
				}
				return t
			}
			, is_manager: function (){

				let t = false
				try{
					if(this.is_boss || this.item_cartel.manager.indexOf(this.user.id) > -1){
						t = true
					}
				}catch (e) {
					t = false
					console.log(e)
				}
				return t
			}
			, is_underboss_manage: function(){
				let t = false
				if(this.item_permission.undrbos_mangement_fg == 'Y'){
					t = true
				}
				return t
			}
			, is_board_manage: function(){
				let t = false
				if(this.item_permission.board_creation_fg == 'Y'){
					t = true
				}
				return t
			}
			, is_member_manage: function(){
				let t = false
				if(this.item_permission.member_mangement_fg == 'Y'){
					t = true
				}
				return t
			}
		}
		, methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data

						switch (this.item_cartel.cartl_concern_sphere_code){
							case 'CM00400001': this.$set(this.item_cartel, 'type', 'music'); break
							case 'CM00400002': this.$set(this.item_cartel, 'type', 'art') ; break
							case 'CM00400003': this.$set(this.item_cartel, 'type', 'fashion'); break
							case 'CM00400004': this.$set(this.item_cartel, 'type', 'influence'); break
						}
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			,postCartelActivity: async function(is_active){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_activity
						, data: {
							cartel_id: this.$route.params.idx
							, is_active: is_active
						}
						, name: 'postCartelActivity'
					})
					if(result.success){
						this.$set(this.item_cartel, 'is_active', is_active)
						this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
					}else{
						throw result.message
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
					console.log(e)
				}finally {
					this.is_confirm = false
					this.$bus.$emit('on', false)
				}
			}
			, getPermission: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_permission
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})
					if(result.success){
						this.item_permission = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, setMenu: function(item){
				this.item_menu = item
				this.$bus.$emit('to', item.to)
			}
		}
		, async created() {
			this.$bus.$emit('onLoad', this.program)
			await this.getData()
			await this.getPermission()
		}
	}
</script>